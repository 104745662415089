export const GET_TRANSACTION_HISTORY ='GET_TRANSACTION_HISTORY'
export const SET_ORDERS_PROP ='SET_ORDERS_PROP'
export const IMPORT_BASKET ='IMPORT_BASKET'
export const LIST_PAYMENT_CARDS ='LIST_PAYMENT_CARDS'
export const ADD_PAYMENT_CARD ='ADD_PAYMENT_CARD'
export const REMOVE_PAYMENT_CARD ='REMOVE_PAYMENT_CARD'
export const CREATE_ORDER ='CREATE_ORDER'
export const GET_ORDER_HISTORY ='GET_ORDER_HISTORY'
export const SET_SCROLL_TOP = 'SET_SCROLL_TOP'
export const ADD_SCANNED_CARD = 'ADD_SCANNED_CARD'
export const SET_DELIVERY_OPTION = 'SET_DELIVERY_OPTION'
export const SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS'
export const SET_DELIVERY_TIME = 'SET_DELIVERY_TIME'
export const SET_PICK_UP_POINT = 'SET_PICK_UP_POINT'
export const SET_PAYMENT_TOKEN = 'SET_PAYMENT_TOKEN'
export const ADD_DELIVERY_ADDRESS = 'ADD_DELIVERY_ADDRESS'
export const POSTCODE_CHECK = 'POSTCODE_CHECK'
export const SET_POSTCODE_DATA = 'SET_POSTCODE_DATA'
export const GET_NEAREST_LOCATION = 'GET_NEAREST_LOCATION'
export const LOCATION_CODE_CHECK = 'LOCATION_CODE_CHECK'
export const SET_LOCATION_CODE_DATA = 'SET_LOCATION_CODE_DATA'
export const ADD_PICKUP_POINT = 'ADD_PICKUP_POINT'
export const STORE_DELIVERY_ADDRESS = 'STORE_DELIVERY_ADDRESS'
export const STORE_PICKUP_POINT = 'STORE_PICKUP_POINT'
export const REMOVE_DELIVERY_ADDRESS ='REMOVE_DELIVERY_ADDRESS'
export const CHECK_CANCEL_ORDER ='CHECK_CANCEL_ORDER'
export const STORE_ITEM_WEB ='STORE_ITEM_WEB'
