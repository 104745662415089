import React from 'react'
import { connect } from 'react-redux'
import { IonCard, IonCardContent, IonIcon, IonItem, IonInput, IonButton, IonSpinner, IonList, IonRadioGroup, IonLabel, IonRadio } from '@ionic/react'
import { closeCircle, checkmarkCircle } from 'ionicons/icons'
import Layout from '../../components/layout'
import { StrongText, SmallText, Spacer, Title, FieldError } from '../../components/common'
import { withTranslation } from '../../lib/translate'
import { forwardTo, forwardToDeliveryOption, sprintf, isEmptyObject, validateForm } from '../../lib/utils'
import { setDeliveryAddress, postCodeCheck, setPostCodeData, getNearestLocation, setCommonModal, storeDeliveryAddress, saveDeliveryDataTemporarily } from '../../store/actions'
import Basket from '../../lib/basket'

import '../clickAndCollect/index.css'
import './index.css'

// const dummyValidAddressFromPostalCode = {
// 	addressLine1: 'Wilton Road'
// }

class DeliveryAddressCheck extends React.Component {
	state = {
		initial: true,
		checking: false,
		postalCode: '',
		postalCodeValid: false,
		deliveryZone: [],
		deliveryZoneOption: '',
		deliveryPrice: '',
		restaurant: {},
		validPostCode: false,
		checkMarkFlag: null,
		minOrder: 0,
		form: {
			addressLine1: '',
			addressLine2: '',
			place: ''
		},
		formErrors: {}
	}

	formConfig = {
		addressLine1: { required: true },
		addressLine2: { required: true },
		place: { required: true }
	}

	ref = React.createRef()

	handleInput = (key, val, e) => {
		const form = {
			...this.state.form,
			[key]: val
		}

		this.setState({
			form,
			formErrors: validateForm(this.formConfig, form)
		})
	}

	checkDelivery = () => {
		if (!Basket.getDeliveryOption()) {
			forwardToDeliveryOption()
		}
	}

	componentDidMount() {
		const { postalCode } = this.state
		const { tempData } = this.props
		this.checkDelivery()
		this.setStatesInitially()
		this.setState({
			formErrors: validateForm(this.formConfig, this.state.form)
		})

		this.checkPostCode(postalCode || tempData?.postalCode)
	}

	componentDidUpdate(prevProps, prevState) {
		const { postalCode } = this.state
		this.checkDelivery()
		if (this.props.checkedCodeData.length > 0 && prevProps.checkedCodeData !== this.props.checkedCodeData) {
			if (this.props.checkedCodeData.length === 1) {
				const minOrder = this.props.checkedCodeData[0].delivery_zone.min_order
				Basket.setMinOrder(minOrder)
				this.setState({ minOrder, checking: false, postalCodeValid: true, restaurant: this.props.checkedCodeData[0], deliveryPrice: this.props.checkedCodeData[0].delivery_zone.price, checkMarkFlag: 'success' })
			} else if (this.props.checkedCodeData.length > 1) {
				let deliveryZone = this.props.checkedCodeData
				this.setState({ checking: false, postalCodeValid: true, deliveryZone, checkMarkFlag: 'success' }, () => {
					this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', true))
				})
			}
		} else if (prevState.checking && this.props.checkedCodeData.length === 0) {
			this.setState({ checking: false, postalCodeValid: false, checkMarkFlag: 'danger' })
		}

		if (prevState.postalCode !== postalCode) {
			this.checkPostCode(postalCode)
		}
	}

	setStatesInitially = () => {
		const { form, postalCode } = this.state
		const { tempData } = this.props

		if (!form?.addressLine1 && !form?.addressLine2 && !form?.place) {
			this.setState({
				form: {
					...form,
					addressLine1: tempData?.addressLine1,
					addressLine2: tempData?.addressLine2,
					place: tempData?.place
				}
			})
		}

		if (!postalCode) {
			this.setState({ postalCode: tempData?.postalCode })
		}
	}

	checkPostCode = (value) => {
		// let reg = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/
		// let postalCodeValid = reg.test(value)
		if (value?.length >= 3) {
			this.setState({ postalCodeValid: true, postalCode: value, initial: false, checkMarkFlag: null })
		}
		else {
			this.setState({ postalCodeValid: false, postalCode: value, initial: false, checkMarkFlag: null }, () => {
				this.props.dispatch(setPostCodeData({ 'data': [] }))
			})
		}
	}

	setPostalCode = (e) => {
		const val = e.target.value || e.detail.value
		this.checkPostCode(val)
	}

	check = (value, type) => {
		const { form, postalCode } = this.state
		const fullAaddress = form.addressLine2 ? `${form.addressLine1}, ${form.addressLine2}, ${form.place}, ${value}` : `${form.addressLine1}, ${form.place}, ${value}`
		const tempData = {
			addressLine1: form.addressLine1,
			addressLine2: form.addressLine2,
			place: form.place,
			postalCode
		}


		if (type === 'postcode') {
			this.props.dispatch(postCodeCheck(fullAaddress))
			this.props.dispatch(saveDeliveryDataTemporarily(tempData))
			this.setState({ initial: false, checking: true })
		} else if (type === 'distance' || type === 'polygon') {
			this.props.dispatch(getNearestLocation(fullAaddress))
			this.props.dispatch(saveDeliveryDataTemporarily(tempData))
			this.setState({ initial: false, checking: true, restaurant: {}, deliveryPrice: '' })
		} else {
			this.setState({ initial: true }, () => {
				this.props.dispatch(setPostCodeData({ 'data': [] }))
				this.props.dispatch(saveDeliveryDataTemporarily(tempData))
			})
		}
		this.setState({ initial: false, checking: true })
	}

	saveAndContinue = () => {
		const { postalCode, restaurant } = this.state
		const { restaurants } = this.props
		const minOrder = restaurant.delivery_zone.min_order
		this.props.dispatch(setDeliveryAddress({ postalCode }))
		Basket.setRestaurant(restaurants.find(res => res.id === restaurant.restaurant_id))
		Basket.setDeliveryPrice(this.state.deliveryPrice)
		this.props.dispatch(storeDeliveryAddress(this.state.form))
		Basket.setDeliveryAddress({ ...this.state.form, postalCode: postalCode })
		Basket.setMinOrder(minOrder)
		forwardTo('/delivery-address-add')
	}

	restaurantName = (restaurant) => {
		if (restaurant && restaurant.restaurant_id) {
			return restaurant.restaurant_name
		}
		return ''
	}

	restaurantAddress = (restaurant) => {
		if (restaurant && restaurant.restaurant_id) {
			return restaurant.restaurant_address
		}
		return ''
	}

	changeDeliveryZone = event => this.setState({ deliveryZoneOption: event.detail.value, error: '' })

	setDeliveryPrice = () => {
		let option = this.state.deliveryZoneOption
		let deliveryPrice = this.props.checkedCodeData[option].delivery_zone.price
		this.setState({ deliveryPrice, restaurant: this.props.checkedCodeData[option] }, () => {
			this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false))
		})
	}
	render() {
		const { __, checkedCodeData, isChooseDeliveryModalOpen, deliveryRangeType } = this.props
		const { initial, checking, postalCode, postalCodeValid, deliveryZone, deliveryZoneOption, restaurant, checkMarkFlag, deliveryPrice, minOrder, form, formErrors } = this.state
		let formValid = Object.keys(formErrors).length === 0
		const animationMenuClass = isChooseDeliveryModalOpen ? 'show-up' : ''
		const deliveryOption = Basket.getDeliveryOption()
		for (const field in form) {
			if (form[field]) {
				delete formErrors[field]
				if (formValid) {
					formValid = true
				} else {
					formValid = false
				}
			}

		}

		return (
			<Layout headerTitle={__(deliveryOption ? deliveryOption.label : '')} noPadding color="transparent">
				<div className="absolute-content delivery-address-bg" title={deliveryOption ? deliveryOption.label : ''}></div>
				<IonCard color="white" className="restaurant-card scrollable-y">
					<IonCardContent className="flex-row-wrapper">
						<Title>{__('Add New Address')}</Title>
						<StrongText color="primary" className="heading thiner-text">{__('Let\'s check if we deliver to you...')}</StrongText>
						<Spacer size="1" />
						<form autoComplete="on">
							<IonItem>
								<div className="inline-input">
									<SmallText>{__('Address 1')}</SmallText>
									<IonInput required={true} value={form?.addressLine1} autocomplete="street-address" onIonChange={e => this.handleInput('addressLine1', e.target.value, e)} type="text" clearInput />
								</div>
							</IonItem>
							{formErrors.addressLine1 ? <FieldError className="field-error" value={__(formErrors.addressLine1)} /> : null}
							<IonItem>
								<div className="inline-input">
									<SmallText>{__('Address 2')}</SmallText>
									<IonInput value={form?.addressLine2} autocomplete="street-address" onIonChange={e => this.handleInput('addressLine2', e.target.value, e)} type="text" clearInput />
								</div>
							</IonItem>
							{formErrors.addressLine2 ? <FieldError className="field-error" value={__(formErrors.addressLine2)} /> : null}
							<IonItem>
								<div className="inline-input">
									<SmallText>{__('Town')}</SmallText>
									<IonInput required={true} value={form?.place} autocomplete="on" onIonChange={e => this.handleInput('place', e.target.value, e)} type="text" clearInput />
								</div>
							</IonItem>
							{formErrors.place ? <FieldError className="field-error" value={__(formErrors.place)} /> : null}
							<IonItem>
								<div className="inline-input">
									<SmallText>{__('Postcode')}</SmallText>
									<IonInput className="strong-text" required={true} value={postalCode?.toUpperCase()} autocomplete="postal-code" onIonChange={(e) => this.setPostalCode(e)} type="text" />
									{initial || checking || !checkMarkFlag ? null :
										<IonIcon size="small" color={checkMarkFlag} icon={checkMarkFlag === 'success' ? checkmarkCircle : closeCircle} />
									}
								</div>
							</IonItem>
							<Spacer />
							<div className="address-checking-box centered">
								{initial ? null : checking ?
									<>
										<br />
										<div><IonSpinner /></div>
										<SmallText>{__('Checking nearest locations')}</SmallText>
									</>
									:
									postalCodeValid && checkedCodeData.length > 0 ?
										isEmptyObject(restaurant) ? null :
											<>
												<SmallText>{__('Your order will be delivered from:')}</SmallText>
												<br /><SmallText>{this.restaurantName(restaurant)}, {this.restaurantAddress(restaurant)}</SmallText>
												<br />
												<br />
												{deliveryPrice > 0 ? <SmallText>{__('A small delivery charge of') + ' ' + Basket.formatPrice(deliveryPrice) + ' ' + __('will apply')} {minOrder === 0 ? null : sprintf(__('Minimum order ' + Basket.formatPrice(minOrder)), 'small')} </SmallText> : <SmallText>{sprintf(__('There is no delivery charge from this location'), 'small')}</SmallText>}
											</>
										:
										checkedCodeData.length === 0 && !checkMarkFlag ? null :
											<>
												<SmallText color="primary">{__('Unfortunately, we don\'t deliver to you yet')}</SmallText>
												<Spacer size={3} />
												<IonButton expand="block" color="tertiary" fill="clear" className="link underlined" onClick={() => forwardTo('/')}>{__('Back Home')}</IonButton>
											</>
								}
							</div>
						</form>
						<Spacer />
						<div className="flex-min">
							{
								postalCodeValid && checkedCodeData.length > 0 && checkMarkFlag ?
									<IonButton disabled={!postalCodeValid || deliveryPrice === '' || !formValid} expand="block" color="secondary" onClick={this.saveAndContinue}>
										{__('Continue ')}
									</IonButton>
									:
									<IonButton className="no-margin" disabled={!postalCodeValid || !formValid} expand="block" color="secondary" onClick={() => { this.check(postalCode, deliveryRangeType) }}>{__('Check postcode')}</IonButton>
							}
						</div>
					</IonCardContent>
				</IonCard>
				<div
					className="click-collect-pickers-backdrop"
					style={{ display: isChooseDeliveryModalOpen ? '' : 'none' }}
					onClick={() => this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false))}>
				</div>
				<div className={`click-collect-dialog ${animationMenuClass}`}>
					<div className="click-collect-dialog-layout sc-ion-modal-md">
						<div className="click-collect-dialog-header">
							<h3>{__('Choose delivery')}</h3>
						</div>
						<div
							className="click-collect-dialog-closer"
							style={{ position: 'absolute', right: 0, top: 0 }}
							onClick={() => this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false))}
						>
							<ion-icon name="close" role="img" className="md hydrated" aria-label="close"></ion-icon>
						</div>
						<div className="click-collect-dialog-content">
							<IonList lines="full">
								<IonRadioGroup onIonChange={this.changeDeliveryZone} value={deliveryZoneOption}>
									{deliveryZone.sort((a, b) => {
										return a.delivery_zone.price - b.delivery_zone.price
									}).map((restaurant, i) => {
										const { restaurant_name } = restaurant
										const price = restaurant.delivery_zone.price
										return (
											<IonItem key={i}>
												<div tabIndex="-1"></div>
												<IonRadio
													color="primary"
													slot="start"
													value={i}
												/>
												<IonLabel className="ion-text-wrap" color="dark">
													{price > 0 ? restaurant_name + ' delivery price - ' + Basket.getCurrency().label + price : restaurant_name + ' - ' + __('Free Delivery')}
												</IonLabel>
											</IonItem>
										)
									})
									}
								</IonRadioGroup>
							</IonList>
						</div>
						<div className="click-collect-dialog-action">
							<IonButton disabled={deliveryZoneOption === ''} expand="block" color="primary" onClick={() => { this.setDeliveryPrice() }}>
								{__('Continue ')}
							</IonButton>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

const stateToProps = state => {
	const { profile } = state.profile
	const { checkedCodeData } = state.orders
	const { deliveryRangeType, isChooseDeliveryModalOpen } = state.common
	const { restaurants, tempData } = state.restaurants
	return {
		profile,
		checkedCodeData,
		deliveryRangeType,
		isChooseDeliveryModalOpen,
		restaurants,
		tempData
	}
}

export default connect(stateToProps)(withTranslation(DeliveryAddressCheck))
