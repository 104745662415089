import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IonButton, IonIcon, IonCard, IonCardContent, IonItem, IonList, IonRadioGroup, IonLabel, IonRadio } from '@ionic/react'
import { restaurant } from 'ionicons/icons'
import Layout from '../../components/layout'
import { getRestaurants, getIkentooMenu, getIkentooMenusForLocation } from '../../store/restaurants/actions'
import { setCommonModal } from '../../store/common/actions'
import { Title, FieldError, Sectiontitle, Spacer, SmallText } from '../../components/common'
import { withTranslation } from '../../lib/translate'
import { isDefined, checkForDeliveryOption, isObject, isArray } from '../../lib/utils'
import moment from '../../lib/moment'
import Loading from '../../components/spinner'
import Basket from '../../lib/basket'
import Mobiscroll from '../../components/mobiscroll'
import { setDeliveryOption } from '../../store/actions'
import './index.css'
import NoData from '../../components/noData'
import { getConfig } from '../../appConfig'
import mobiscroll from '@mobiscroll/react';
import { AlertEnum } from './../../core/enums/alert-enum';
import { checkSnoozedTimes } from '../delivery-time'

const { SelectOption } = Mobiscroll
// const customTime = '2020-06-04T02:00:10.546'

const createMomentFromTime = (time = '') => {
	const parsedTime = time.split(':')
	if (parsedTime.length !== 2 || time === '') {
		return null
	}
	let hour = parseInt(time.split(':')[0])
	let minutes = parseInt(time.split(':')[1])
	return moment().hours(hour).minutes(minutes)
}

const toWhichSegmentTimeBelongs = (time, segments = []) => {
	let timeIsInSegment = -1
	segments.forEach((segment, index) => {
		const { start, end } = segment
		const targetTime = createMomentFromTime(time)
		const segmentStart = createMomentFromTime(start)
		const segmentEnd = createMomentFromTime(end)
		if (targetTime.isSameOrAfter(segmentStart) && targetTime.isSameOrBefore(segmentEnd)) {
			timeIsInSegment = index
		}
	})
	return timeIsInSegment
}

const isTimeInSegment = (time, segments = []) => {
	return toWhichSegmentTimeBelongs(time, segments) !== -1
}

const addSegment = (start, end, segments = [], date) => {
	let updatedSegments = [...segments]
	const dayNumber = 'w' + date.day()
	const newSegment = { 'd': dayNumber, 'start': start, 'end': end }

	// check previously added segment. maybe some of them are handled with new added segment
	const oldSegments = [...segments]
	oldSegments.forEach((oldSegment, index) => {
		if (isTimeInSegment(oldSegment.start, [newSegment]) && isTimeInSegment(oldSegment.end, [newSegment])) {
			updatedSegments = removeSegment(index, updatedSegments)
		}
	})
	return [...updatedSegments, newSegment]
}

const updateSegment = (segmentIndex, propName, value, segments = []) => {
	let updatedSegments = [...segments]
	if (updatedSegments && updatedSegments[segmentIndex]) {
		updatedSegments[segmentIndex][propName] = value
	}
	return updatedSegments
}

const removeSegment = (segmentIndexForRemove, segments = []) => [...segments].map((segment, index) => index === segmentIndexForRemove ? null : segment).filter(segment => isDefined(segment))

const parseTimesJson = (json = [], date) => {
	let parsed_json = []
	const dayNumber = 'w' + date.day()

	// use old logic for 'json_time_selector' json (without: menuId and availabity)
	if (json.length > 0 && !json[0].menuId) {
		return json
	}

	json.forEach(menu => {
		(menu.availability || []).filter(i => i.d === dayNumber).forEach(time => {
			const { start, end } = time
			if (parsed_json.length === 0) {
				// add first available time
				parsed_json = addSegment(start, end, parsed_json, date)
			} else {
				if (!isTimeInSegment(start, parsed_json) && !isTimeInSegment(end, parsed_json)) {
					// case 1: start and end dont belong to any other segment
					parsed_json = addSegment(start, end, parsed_json, date)
				} else if (isTimeInSegment(start, parsed_json) && !isTimeInSegment(end, parsed_json)) {
					// case 2: start belong to some segment and end dont bolong to any segment
					const segmentIndex = toWhichSegmentTimeBelongs(start, parsed_json)
					parsed_json = updateSegment(segmentIndex, 'end', end, parsed_json)
				} else if (!isTimeInSegment(start, parsed_json) && isTimeInSegment(end, parsed_json)) {
					// case 3: end belong to some segment and start dont bolong to any segment
					const segmentIndex = toWhichSegmentTimeBelongs(end, parsed_json)
					parsed_json = updateSegment(segmentIndex, 'start', start, parsed_json)
				} else if (isTimeInSegment(start, parsed_json) && isTimeInSegment(end, parsed_json)) {
					// case 4: and start and end belongs to some segment
					const startSegmentIndex = toWhichSegmentTimeBelongs(start, parsed_json)
					const endSegmentIndex = toWhichSegmentTimeBelongs(end, parsed_json)
					if (parsed_json && parsed_json[startSegmentIndex] && parsed_json[endSegmentIndex]) {
						const newStartTime = parsed_json[startSegmentIndex].start
						const newEndTime = parsed_json[endSegmentIndex].end

						if (startSegmentIndex !== endSegmentIndex) {
							parsed_json = addSegment(newStartTime, newEndTime, parsed_json, date)
							parsed_json = removeSegment(startSegmentIndex, parsed_json)
							parsed_json = removeSegment(endSegmentIndex, parsed_json)
						}
					}
				}
			}
		})
	})

	// sort times by 'start' time
	return parsed_json.sort((a, b) => {
		const aStart = createMomentFromTime(a.start)
		const bStart = createMomentFromTime(b.start)
		return bStart.isSameOrBefore(aStart) ? 1 : -1
	})
}

/*
menusForLocation: [{menuName: "Winter Menu 2014", ikentooMenuId: 37082747671397}, ...]
pickTime: 12:45
json_time_selector: [{
	"menuId": 37082747671609,
	"availability": [
		{ "d": "w1", "start": "07:15", "end": "15:45" },
		{ "d": "w2", "start": "07:15", "end": "15:45" },
		{ "d": "w3", "start": "07:15", "end": "15:45" },
		{ "d": "w4", "start": "07:15", "end": "15:45" },
		{ "d": "w5", "start": "07:15", "end": "15:45" }
	]
}, ... ]
*/
export const getMenusForSelectedTime = (menusForLocation = [], pickTime, json_time_selector = []) => {
	if (isObject(menusForLocation) && !isArray(menusForLocation)) {
		menusForLocation = [menusForLocation]
	}
	if (pickTime === 'asap') {
		return menusForLocation
	} else {
		return menusForLocation.filter(menu => {
			const ikentooMenuId = menu.ikentooMenuId

			if (json_time_selector.length > 0 && json_time_selector[0].menuId && pickTime) {
				const target_menu = json_time_selector.find(i => i.menuId === ikentooMenuId)
				if (target_menu && isTimeInSegment(pickTime, target_menu.availability.filter(i => i.d === 'w' + moment().day()))) {
					return true
				}
			} else {
				// handle old json_time_selector (without menuId and availability)
				return false
			}

			return false
		})
	}
}

export const formatDataForTime = (store, minDate, selectedRestaurant, isCharterDelivery, snoozedTimes) => {
	let timesInBetween = [];
	let storeTimes = [];
	const charterDeliveryPeriod =
		store && store.charter_delivery_order_slot_interval_mins
			? store.charter_delivery_order_slot_interval_mins
			: 30;
	const clickAndCollectPeriod =
		store && store.order_slot_interval_mins ? store.order_slot_interval_mins : 5;
	const minDT = minDate.format('HH:mm');
	const date = moment(minDate);
	const dow = 'w' + date.day();
	const period = isCharterDelivery ? charterDeliveryPeriod : clickAndCollectPeriod;
	const collection_minutes = [];
	let minutes = -period;

	while (minutes < 60) {
		minutes += period;
		if (minutes < 60) {
			collection_minutes.push(minutes);
		}
	}
	if (store) {
		parseTimesJson(
			isCharterDelivery ? store.charter_delivery_times_json : store.json_time_selector,
			date,
		).forEach((time) => {
			if (time.d === dow) {
				storeTimes.push({ ...time });
			}
		});
	}

	function makePickerText(times, j, i) {
		let collectionMinutes = parseInt(times[j]) < 10 ? '0' + times[j] : times[j];
		return {
			text: i < 10 ? '0' + i + ':' + collectionMinutes : i + ':' + collectionMinutes,
			value: i < 10 ? '0' + i + ':' + collectionMinutes : i + ':' + collectionMinutes,
		};
	}

	function returnTimesInBetween(start, end, cnt) {
		let startH = parseInt(start.split(':')[0]);
		let startM = parseInt(start.split(':')[1]);
		let endH = parseInt(end.split(':')[0]);
		let endM = parseInt(end.split(':')[1]);
		let minTimeStart = parseInt(minDT.split(':')[0]);
		let minTimeEnd = parseInt(minDT.split(':')[1]);
		let c = collection_minutes.filter((cm) => cm >= startM);
		let cm = collection_minutes.filter((cm) => cm <= endM);
		let startHH = startH;
		if (startHH <= minTimeStart) {
			startHH = minTimeStart;
		}

		for (let i = startHH; i <= endH; i++) {
			if (startH === i) {
				for (let j = 0; j < c.length; j++) {
					if (c[j] >= minTimeEnd && cnt === 0 && startH <= minTimeStart) {
						timesInBetween.push(makePickerText(c, j, i));
					} else if (cnt !== 0) {
						timesInBetween.push(makePickerText(c, j, i));
					} else if (startH > minTimeStart) {
						timesInBetween.push(makePickerText(c, j, i));
					}
				}
			} else if (endH === i) {
				if (minTimeStart === i) {
					for (let j = 0; j < cm.length; j++) {
						if (cm[j] >= minTimeEnd) {
							timesInBetween.push(makePickerText(cm, j, i));
						}
					}
				} else {
					for (let j = 0; j < cm.length; j++) {
						timesInBetween.push(makePickerText(cm, j, i));
					}
				}
			} else {
				if (i === minTimeStart) {
					let collection_m = collection_minutes.filter((cm) => cm >= minTimeEnd);
					for (let j = 0; j < collection_m.length; j++) {
						timesInBetween.push(makePickerText(collection_m, j, i));
					}
				} else {
					for (let j = 0; j < collection_minutes.length; j++) {
						timesInBetween.push(makePickerText(collection_minutes, j, i));
					}
				}
			}
		}

		//if we have no oppning times, just add 'CLOSED' label to the picker
		if (
			timesInBetween &&
			timesInBetween.length > 1 &&
			timesInBetween[0] &&
			timesInBetween[0].text === 'CLOSED'
		) {
			timesInBetween.shift();
		}
		//if we have no oppning times, just add 'CLOSED' label to the picker
		if (isDefined(store) && timesInBetween.length === 0) {
			timesInBetween.push({ text: 'CLOSED', value: null });
		}
		return timesInBetween;
	}
	storeTimes.forEach((storeT, i, arr) => {
		returnTimesInBetween(storeT.start, storeT.end, i);
		let minH = parseInt(minDT.split(':')[0]);
		let minM = parseInt(minDT.split(':')[1]);
		let endTimeH = parseInt(storeT.end.split(':')[0]);
		let endTimeM = parseInt(storeT.end.split(':')[1]);
		let minTime = date.hours(minH).minutes(minM);
		let timeEnd = date.hours(endTimeH).minutes(endTimeM);
		if (i < arr.length - 1 && arr.length > 0 && moment(minTime).isSameOrBefore(timeEnd)) {
			timesInBetween.push({ text: 'CLOSED', value: null });
		}
	});

	//remove 'CLOSED' label if that is first time
	if (
		timesInBetween &&
		timesInBetween.length > 1 &&
		timesInBetween[0] &&
		timesInBetween[0].text === 'CLOSED'
	) {
		timesInBetween.shift();
	}
	if (timesInBetween.length === 0 && selectedRestaurant) {
		timesInBetween.push({ text: 'CLOSED', value: null });
	}

	return timesInBetween.map((time) => ({
		...time,
		disabled: snoozedTimes.length > 0 && snoozedTimes.includes(time.text),
		html: `<span class='${snoozedTimes.length > 0 && snoozedTimes.includes(time.text) ? 'snoozed' : ''}'>${time.text}</span>`,
	}));
};

class ClickAndCollect extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedRestaurant: null,
			pickTime: null,
			error: '',
			modalOpen: false,
			isLocationAllowed: false
		}
	}

	componentDidMount() {
		Basket.setOrderType('collection')
		const deliveryOption = checkForDeliveryOption(this.props.deliveryOption, '/click-and-collect')
		if (deliveryOption) {
			this.props.dispatch(getRestaurants())
			this.props.dispatch(setDeliveryOption(deliveryOption))
		}
		if (Basket.getMenu()) {
			this.setState({ selectedIkentooMenu: Basket.getMenu() })
		}
		this.position()
	}

	selectRestaurant = (event, data) => {
		const { restaurants, profile } = this.props
		const selectedRestaurantId = data.getVal()
		this.setState({ selectedRestaurant: selectedRestaurantId, pickTime: null }, () => {
			Basket.reset(profile.cardToken)
			Basket.setRestaurant(restaurants.find(restaurant => restaurant.id === selectedRestaurantId))
			Basket.setCollectionTime(null)
			Basket.setOrderType('collection')
		})
	}

	changeTime = (selectedTime, minDT) => {
		if (selectedTime && minDT) {
			let h = parseInt(selectedTime.split(':')[0])
			let m = parseInt(selectedTime.split(':')[1])
			const formattedDT = moment(minDT).hours(h).minutes(m)
			this.setState({ pickTime: selectedTime }, () => {
				Basket.setCollectionTime(formattedDT)
				Basket.setOrderType('collection')
			})
		} else {
			this.setState({ pickTime: null })
		}
	}


	componentDidUpdate(prevProps) {
		checkForDeliveryOption(this.props.deliveryOption, '/click-and-collect')
		if (this.props.ikentooMenusForLocation.length !== prevProps.ikentooMenusForLocation.length) {
			if (this.props.ikentooMenusForLocation[0]) {
				this.setState({ selectedIkentooMenu: this.props.ikentooMenusForLocation[0].ikentooMenuId })
			}
		}
	}

	setPickTime = (pickTime, inst, minDT) => {
		if (inst.getVal()) {
			this.changeTime(inst.getVal(), minDT)
		} else {
			this.setState({ pickTime: null })
		}
	}

	continueOnMenu = () => {
		const { restaurants, dispatch } = this.props
		const { selectedIkentooMenu, selectedRestaurant } = this.state
		if (selectedRestaurant && selectedIkentooMenu) {
			const choosenRestaurant = restaurants.find(restaurant => restaurant.id === selectedRestaurant)
			const businessLocationId = choosenRestaurant.business_location_id
			dispatch(getIkentooMenu(selectedIkentooMenu, businessLocationId))
		} else {
			this.setState({ error: 'Please select location menu' })
		}
	}

	chooseMenusForLocation = () => {
		const { restaurants, dispatch } = this.props
		const { selectedRestaurant, pickTime } = this.state
		if (selectedRestaurant && pickTime) {
			const choosenRestaurant = restaurants.find(restaurant => restaurant.id === selectedRestaurant)
			const businessLocationId = choosenRestaurant.business_location_id
			let cutoffTimeRes = moment().unix()
			Basket.setCutoffTime(cutoffTimeRes)
			dispatch(getIkentooMenusForLocation(businessLocationId, { pickTime, json_time_selector: choosenRestaurant ? choosenRestaurant.json_time_selector : [] }))
		} else if (!selectedRestaurant) {
			this.setState({ error: 'Please select location' })
		} else {
			this.setState({ error: 'Please select pickup time' })
		}
	}

	changeIkentooMenus = event => this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () => {
		Basket.setMenu(event.detail.value)
	})

	position = async () => {
		await navigator.geolocation.getCurrentPosition(
			position => this.setState({
				isLocationAllowed: true
			}),
			err => console.log(err)
		)
	}

	formatDataForSelect = (stores) => {
		const { __ } = this.props
		let arrForSelect = []
		stores.forEach((store) => {
			if (isDefined(store.can_collection_order) && isDefined(store.is_published)) {
				if (store.can_collection_order && store.is_published) {
					arrForSelect.push({ text: store.name, value: store.id })
				}
			}
		})
		if (!this.state.isLocationAllowed) {
			arrForSelect.sort(function (a, b) {
				return a.text < b.text ? -1 : a.text > b.text ? 1 : 0
			})
		}
		return [{ text: __('Select Collection Location'), value: null }, ...arrForSelect]
	}

	render() {
		const { __, restaurants, ikentooMenusForLocation, isChooseMenuModalOpen, deliveryOption, orderProductionMins } = this.props
		const { error, modalOpen, selectedRestaurant, pickTime, selectedIkentooMenu } = this.state
		const animationClass = modalOpen ? 'show-up' : ''
		const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : ''
		const store = restaurants.find(restaurant => restaurant.id === selectedRestaurant) || null
		const stores = restaurants || []
		const mins = isDefined(orderProductionMins) ? orderProductionMins : 15

		const currentDT = moment()

		// const timeFormat = 'HH:mm'

		// Added period to prevent time in past 12:51 = 12:55 should be possible to select (min time)
		let minDT = currentDT

		//include 'Order slot lead time' from the BO
		if (store && isDefined(store.order_slot_lead_time)) {
			minDT.add(store.order_slot_lead_time, 'minutes')
		}
		const snoozedTimes = checkSnoozedTimes(store, 'collection')

		const timePickerOptions = formatDataForTime(store, minDT, selectedRestaurant, false, snoozedTimes)
		const menus = getMenusForSelectedTime(ikentooMenusForLocation, pickTime, store ? store.json_time_selector : [])

		const showSnoozedAlert = (e) => {
			if (snoozedTimes.includes(e.valueText)) {
				mobiscroll.alert({ title: AlertEnum.TITLE.WARNING, message: AlertEnum.MESSAGE.BUSY });
				return false;
			}
		};
		/**/
		return (
			<Layout headerTitle={__(deliveryOption ? deliveryOption.label : '')} noPadding color="transparent">
				<div className="absolute-content restaurant-gradient"></div>
				<div className="delivery-option-label"><Title>{deliveryOption ? __(deliveryOption.label) : ''}</Title></div>
				<IonCard color="white" className="restaurant-card">
					<div className="delivery-option-label web-only"><Title>{deliveryOption ? __(deliveryOption.label) : ''}</Title></div>
					<IonCardContent>
						<IonButton expand="block" color="secondary" onClick={() => this.setState({ modalOpen: true })}>
							<IonIcon slot="start" icon={restaurant} />
							{__('New Order')}
						</IonButton>
						<div className="restaurant-info">
							{__('After completing your order you will be able to collect from the location in')} {getConfig().flags.orderProductionMins ? <strong>{mins} {__('minutes')}.</strong> : null}
							<br /><br />
							{__('This service is currently only available for collection to take away')}.
						</div>
					</IonCardContent>
				</IonCard>
				<div
					className="click-collect-pickers-backdrop"
					style={{ display: modalOpen ? '' : 'none' }}
					onClick={() => {
						this.setState({ modalOpen: false, selectedRestaurant: null, pickTime: null })
					}}>
				</div>
				<div className={`click-collect-dialog ${animationClass}`}>
					<Loading transparent> {null} </Loading>
					<div className="click-collect-dialog-layout sc-ion-modal-md">
						<div className="click-collect-dialog-header">
							<h3>{__('Collection details')}</h3>
							<SmallText className="heading">{__('Start an order to collect from our restaurant')}</SmallText>
						</div>
						<div
							className="click-collect-dialog-closer"
							style={{ position: 'absolute', right: 0, top: 0 }}
							onClick={() => {
								this.setState({ modalOpen: false, selectedRestaurant: null, pickTime: null })
							}}
						>
							<ion-icon name="close" role="img" className="md hydrated" aria-label="close"></ion-icon>
						</div>
						<div className="click-collect-dialog-content">
							<label className="select-picker-label" htmlFor="demo-non-form">{__('Location')}</label>
							<SelectOption
								display="center"
								onSet={(e, a) => this.selectRestaurant(e, a)}
								data={this.formatDataForSelect(stores)}
								label="Location"
								value={this.state.selectedRestaurant}
								inputStyle="box"
								placeholder={__('Select Location')}
								setText={__('OK')}
								cancelText={__('Cancel')}
							/>
							<Spacer size={2} />
							<label className="time-picker-label" htmlFor="demo-non-form">{__('Collection Time')}</label>

							<SelectOption
								display="center"
								onSet={(e, inst) => this.setPickTime(e, inst, minDT)}
								data={timePickerOptions}
								onBeforeClose={(e) => {
									if (e.button !== 'cancel') {
										return showSnoozedAlert(e);
									}
								}}
								label="Location"
								value={pickTime}
								inputStyle="box"
								placeholder={__('Select Collection Time')}
								setText={__('OK')}
								cancelText={__('Cancel')}
								disabled={this.state.selectedRestaurant === null ? true : false}
								onInit={() => {
									if (timePickerOptions.length > 0) {
										const firstAvailableTime = timePickerOptions.find(
											(i) => i.value !== null && !snoozedTimes.includes(i.value),
										);
										if (!pickTime && firstAvailableTime && pickTime !== firstAvailableTime.value) {
											this.changeTime(firstAvailableTime.value, minDT);
										}
									}
								}}
							/>
						</div>
						<div className="click-collect-dialog-action">
							<IonButton disabled={pickTime ? false : true} expand="block" color="secondary" onClick={() => this.chooseMenusForLocation()}>{__('Continue ')}</IonButton>
							{error ? <IonItem><div tabIndex="-1"></div><FieldError className="field-error" value={__(error)} /></IonItem> : null}
						</div>
					</div>
				</div>
				<div
					className="click-collect-pickers-backdrop"
					style={{ display: isChooseMenuModalOpen ? '' : 'none' }}
					onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}>
				</div>
				<div className={`click-collect-dialog ${animationMenuClass}`}>
					<Loading transparent> {null} </Loading>
					<div className="click-collect-dialog-layout sc-ion-modal-md">
						<div className="click-collect-dialog-header">
							<h3>{__('Choose menu')}</h3>
						</div>
						<div
							className="click-collect-dialog-closer"
							style={{ position: 'absolute', right: 0, top: 0 }}
							onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
						>
							<ion-icon name="close" role="img" className="md hydrated" aria-label="close"></ion-icon>
						</div>
						<div className="click-collect-dialog-content">
							<IonList lines="none">
								<IonRadioGroup onIonChange={this.changeIkentooMenus} value={selectedIkentooMenu}>
									{
										menus.length === 0 ? <NoData /> : menus.map(menu => {
											const { ikentooMenuId, menuName } = menu
											return (
												<IonItem key={ikentooMenuId} lines='full'>
													<div tabIndex="-1"></div>
													<IonLabel className="ion-text-wrap">
														<Sectiontitle>{menuName}</Sectiontitle>
													</IonLabel>
													<IonRadio
														color="secondary"
														slot="start"
														value={ikentooMenuId}
													/>
												</IonItem>
											)
										})
									}
								</IonRadioGroup>
							</IonList>
						</div>
						<div className="click-collect-dialog-action">
							{error ? <IonItem><div tabIndex="-1"></div><FieldError className="field-error" value={__(error)} /></IonItem> : null}
							<IonButton disabled={pickTime && menus.length > 0 ? false : true} expand="block" color="secondary" onClick={() => this.continueOnMenu()}>{__('Continue ')}</IonButton>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

const stateToProps = state => {
	const { auth, isChooseMenuModalOpen, orderProductionMins } = state.common
	const { restaurants, ikentooMenu, isShowTimePicker, ikentooMenusForLocation } = state.restaurants
	const { deliveryOption } = state.orders
	return {
		auth,
		isChooseMenuModalOpen: isChooseMenuModalOpen,
		restaurants: restaurants || [],
		ikentooMenu: ikentooMenu || {},
		ikentooMenusForLocation: ikentooMenusForLocation || [],
		profile: state.profile.profile,
		isShowTimePicker: isShowTimePicker,
		deliveryOption,
		orderProductionMins: orderProductionMins
	}
}

export default connect(stateToProps)(withTranslation(ClickAndCollect))
