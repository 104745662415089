import React from 'react'
import { IonCard, IonCardContent, IonButton, IonInput, IonItem } from '@ionic/react'

import Layout from '../../components/layout'
import Loading from '../../components/spinner'
import { Title, Spacer, FieldError, SmallText } from '../../components/common'
import { validateForm } from '../../lib/utils'
import { withTranslation } from '../../lib/translate'
import { sendRefer } from '../../store/actions'

import './index.css'

class ReferAFriend extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			email: '',
			formErrors: {}
		}
		this.formConfig = {
			email: { type: 'email', required: true }
		}
	}

	handleInput (key, val) {
		this.setState({ [key]: val })
	}

	handleRefer = () => {
		const { dispatch, __ } = this.props
		let formErrors = validateForm(this.formConfig, this.state, __)
		this.setState({ formErrors })
		if (Object.keys(formErrors).length === 0) {
			const { email } = this.state
			const data = { email: email }
			this.setState({ email: '' })
			dispatch(sendRefer(data))
		}
	}

	render () {
		const { __ } = this.props
		const { email } = this.state
		return (
			<Loading transparent>
				<Layout color="transparent" headerTitle={ __('Refer a Friend')}>
					<div className="absolute-content refer-a-friend" title="Refer a friend"></div>
					<Title className="centered">{ __('Refer a Friend')}</Title>
					<IonCard color="white" class="raf-card">
						<IonCardContent>
							<div className="raf-info">
								{ __('Once your friend has signed up and used the app you will both receive a reward of 10 points!')}
							</div>
							<Spacer size={1} />
							<IonItem>
								<div className="inline-input">
									<SmallText>{ __('Friend\'s Email:') }</SmallText>
									<IonInput onIonChange={ e => this.handleInput('email', e.target.value)} required placeholder={ __('Enter your friend\'s email')} type="email" pattern="email" inputmode="email" value={ email }/>
								</div>
								{/* <IonInput onIonChange={ e => this.handleInput('email', e.target.value)} required placeholder={ __('Friend\'s email address')} type="email" pattern="email" inputmode="email" value={ email }/> */}
							</IonItem>
							<FieldError className="field-error" value={ __(this.state.formErrors.email)} />
							<Spacer size={1}/>
							<IonButton expand="block" color="secondary" strong={ true } onClick={() => this.handleRefer()}>
								{ __('Send Invitation')}
							</IonButton>
						</IonCardContent>
					</IonCard>
				</Layout>
			</Loading>
		)
	}
}

export default withTranslation(ReferAFriend)
