import { getEnv } from './lib/env.js'

let appConfig = {
	theme: {
		nav: {
			label: 'Napizz'
		},
		showHeaderOnAuthRoutes: true,
		showInputIconsOnLogin: false,
		routesWithoutStaticHeader: [ '/item-details' ]
	},
	services: {
		google_maps: {
			google_api_key: getEnv('GOOGLE_API_KEY')
		}
		// stripe_key: 'pk_test_SfLlfP41LhHVIG2vrA95ZrFt' //Kole
	},
	api: {
		// baseURL: 'https://hybrid-master.herokuapp.com',
		baseURL: 'https://napizz-api.herokuapp.com',
		// baseURL: 'https://4dc746234d1b.ngrok.io', //marko
		// baseURL: 'http://192.168.0.21:8000', //balsa
		wordPressBaseURL: 'https://hybridapp.co.uk'
	},
	general: {
		clientName: 'Napizz',
		appExitRoutes: [ '/', '/home', '/dashboard' ],
		authRoutes: [ '/login', '/register', '/reset-password' ],
		isReduxDevToolsOn: true,
		isWebPlatform: false,
		defaultServiceCharge: 12.5
	},
	appType: {
		hasOrdering: true,
		hasLoyalty: true,
		hasEmailValidationEnabled: true,
		hasCampaignManager: true,
		referAFriend: false,
		hasCancelOrder: true,
		hasServiceCharge: false,
		isReward: false,
		isBooking: true
	},
	delivery: [
		{
			id: 'collection',
			label: 'Click & Collect',
			route: '/click-and-collect'
		},
		// {
		// 	id: 'delivery',
		// 	label: 'Order for Delivery',
		// 	route: '/delivery'
		// },
		{
			id: 'table',
			label: 'Order To Table',
			route: '/order-to-table'
		},
		// {
		// 	id: 'pick-up-point',
		// 	label: 'Order to Pick Up Point',
		// 	route: '/pick-up-point'
		// },
		{
			id: 'charter-delivery',
			// label: 'Order for Charter Delivery',
			label: 'Delivery Order',
			route: '/delivery'
		}
	],
	configS3: {
		imageNamePrefix: 'napizz_profile_image_',
		bucketName: 'hybrid-apps-profile-images',
		region: 'eu-west-1',
		accessKeyId: getEnv('S3_ACCESS_KEY_ID'),
		secretAccessKey: getEnv('S3_SECRET_ACCESS_KEY')
	},
	firebaseConfig: {
		apiKey: getEnv('FIREBASE_API_KEY'),
		authDomain: getEnv('FIREBASE_AUTH_DOMAIN'),
		databaseURL: getEnv('FIREBASE_DATABASE_URL'),
		projectId: getEnv('FIREBASE_PROJECT_ID'),
		storageBucket: getEnv('FIREBASE_STORAGE_BUCKET'),
		messagingSenderId: getEnv('FIREBASE_MESSAGING_SENDER_ID'),
		appId: getEnv('FIREBASE_APP_ID'),
		measurementId: getEnv('FIREBASE_MEASUREMENT_ID')
	},
	payment: 'stripe',
	vouchersType: 3
}

export const updateConfig = newConfig => appConfig = newConfig

export const getConfig = () => appConfig

export default appConfig
