import React from 'react'
import { connect } from 'react-redux'
import { IonButton, IonList, IonItem, IonTextarea } from '@ionic/react'
import Layout from '../../components/layout'
import { Title, SmallText, Spacer } from '../../components/common'
import { withTranslation } from '../../lib/translate'
import { validateForm, forwardTo } from '../../lib/utils'
import { addDeliveryAddress, storeDeliveryAddress, showToast } from '../../store/actions'
import Loading from '../../components/spinner'
import Basket from '../../lib/basket'

import './index.css'

const { getDeliveryAddress, getDeliveryPrice, getMinOrder } = Basket

class DeliveryAddressAdd extends React.Component {
	state = {
		form: {
			addressLine1: getDeliveryAddress().addressLine1 || '',
			addressLine2: getDeliveryAddress().addressLine2 || '',
			place: getDeliveryAddress().place || '',
			postalCode: getDeliveryAddress().postalCode || '',
			driverNotes: null,
			order_type: Basket.getOrderType().toLowerCase()
		},
		formErrors: {}
	}
	formConfig = {
		// postalCode: { required: true }
	}
	handleInput = (key, val) => {
		const form = {
			...this.state.form,
			[key]: val,
			'order_type': this.state.order_type
		}
		this.setState({
			form,
			formErrors: validateForm(this.formConfig, form)
		})
	}
	save = () => {
		const { __, auth, profile, dispatch } = this.props
		const order_type = Basket.getOrderType()
		if (auth && auth.loggedIn) {
			let found = !!(order_type === 'charter-delivery' ?
				profile.charter_delivery_address_list ?
					profile.charter_delivery_address_list : [] : profile.address_list ? profile.address_list : []).find(al => this.state.form.addressLine1 + this.state.form.place + this.state.form.postalCode === al.addressLine1 + al.place + al.postalCode)
			if (!found) {
				this.props.dispatch(addDeliveryAddress({ ...this.state.form, order_type: Basket.getOrderType().toLowerCase() }))
			} else {
				dispatch(showToast(__('Address already exist'), 'warning'))
			}
			Basket.setDeliveryAddress(this.state.form)
		} else {
			this.props.dispatch(storeDeliveryAddress(this.state.form))
			Basket.setDeliveryAddress(this.state.form)
		}
		forwardTo('/delivery-time')
	}

	componentDidMount() {
		this.setState({
			formErrors: validateForm(this.formConfig, this.state.form)
		})
	}

	render() {
		const { __ } = this.props
		const { form } = this.state
		const delivery_adress = getDeliveryAddress()
		const deliveryPrice = getDeliveryPrice()
		const minOrder = getMinOrder()

		return (
			<Loading transparent>
				<Layout>
					<div className="flex-row-wrapper absolute-content">
						<div className="flex-min">
							<Title>{__('Confirm Delivery')}</Title>
							<Spacer />
							<SmallText className="heading">{__('Ordering at') + ' ' + delivery_adress.place + ' ' + __('for delivery to') + ' ' + delivery_adress.addressLine1 + ', ' + delivery_adress.addressLine2 + ', ' + Basket.getRestauranName() + ', ' + Basket.getRestaurant().postcode + '.'}</SmallText>
							<Spacer />
							<SmallText className="heading">{deliveryPrice === 0 ? null : `${__('Delivery charge:')} ${deliveryPrice}.`}</SmallText> <SmallText className="heading">{minOrder === 0 ? null : __(`Minimum order: ${Basket.formatPrice(minOrder)}`)}</SmallText>
							<Spacer />
						</div>
						<div className="scrollable-y">
							<IonList lines="full">
								<IonItem>
									<div className="blocked-input">
										<SmallText className="heading">{__('Drives notes (optional)')}</SmallText>
										<IonTextarea value={form.driverNotes} onIonChange={e => this.handleInput('driverNotes', e.target.value)} type="text" clearInput />
									</div>
								</IonItem>
							</IonList>
						</div>
						<div className="flex-min">
							<Spacer />
							{/* <IonButton disabled={ !formValid } expand="block" color="secondary" onClick={ this.save }>{ __('Save Address & Continue')}</IonButton> */}
							<IonButton expand="block" color="secondary" onClick={this.save}>{__('Continue ')}</IonButton>
						</div>
					</div>
				</Layout>
			</Loading>
		)
	}
}

const stateToProps = state => {
	const { profile, auth } = state.profile
	const { deliveryAddress, storedDeliveryAddress } = state.orders
	return {
		profile,
		auth,
		deliveryAddress,
		storedDeliveryAddress,
		isLoggedIn: profile.auth && profile.auth.loggedIn
	}
}

export default connect(stateToProps)(withTranslation(DeliveryAddressAdd))
