import React from 'react'

import './index.css'

class Clickup extends React.Component {
	state = {
		isOpen: false
	}
	toggleOpen = () => {
		this.setState({ isOpen: !this.state.isOpen })
	}
	render() {
		const { className, label, height, children } = this.props
		const { isOpen } = this.state
		const heightAttr = height && isOpen ? { style: { top: 'calc(100% - ' + parseInt(height, 10) + 'px)' }} : {}
		return (
			<div className={ 'clickup' + (isOpen ? ' open' : '') + (className ? ' ' + className : '')} { ...heightAttr }>
				<div className="clickup-bar" onClick={ this.toggleOpen }>
					<div className="clickup-label">{ label }</div>
					<div className="clickup-caret"></div>
				</div>
				<div className="clickup-content">{ children }</div>
			</div>
		)
	}
}

export default Clickup
