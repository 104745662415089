import React from 'react'
import { withRouter } from 'react-router'
import { IonHeader, IonToolbar, IonMenuButton, IonButtons, IonButton, IonIcon, IonBadge } from '@ionic/react'
import { scan, basket, arrowBack } from 'ionicons/icons'
import { connect } from 'react-redux'
import Basket from '../../lib/basket'
import Icon from '../icon'
import { SmallText } from '../common'
import { forwardTo, getRouteClassName, isWebConfig, goBack } from '../../lib/utils'
import { withTranslation } from '../../lib/translate'
import { getConfig } from '../../appConfig'
import './index.css'

const StaticHeader = ({ __, history }) => {
	const currentPath = history.location.pathname
	const config = getConfig()
	const authPages = config.general.authRoutes.indexOf(currentPath) !== -1
	const hideStaticHeader = config.theme.routesWithoutStaticHeader.indexOf(currentPath) !== -1
	const routeClassName = getRouteClassName(currentPath)
	return (
		<>
			{hideStaticHeader ? null : config.theme.showHeaderOnAuthRoutes || !authPages ?
				<IonHeader className={'static ' + routeClassName}>
					<IonToolbar className="primary-toolbar">
						<IonButtons slot="start">
							{!authPages ?
								<IonMenuButton />
								:
								authPages && !isWebConfig() ?
									<IonButton onClick={() => goBack()}>
										<Icon icon={arrowBack} classname='icon' />
									</IonButton>
									:
									null
								// isLogin ? null : <IonButton color='dark' button clear onClick={ () => forwardTo(!isLogin ? '/login' : '/register') }>{ __(isLogin ? /* 'Sign up' */ null : 'Sign in') }</IonButton>
							}
						</IonButtons>
						<IonButtons color="primary">
							<IonButton className='image-button' color="primary-shade" onClick={() => forwardTo('/dashboard')} />
						</IonButtons>
						<IonButtons slot="end">
							{
								Basket.itemsCount() > 0 || isWebConfig() ?
									<IonButton disabled={Basket.itemsCount() > 0 ? false : true} color='dark' className='basket-button' onClick={() => forwardTo('/order-summary')} size={24}>
										<div>
											<div className='basket-icon-wrapper'>
												<IonBadge slot="end" color='primary' className={Basket.itemsCount() >= 10 ? 'badge' : 'badge-small'}>{Basket.getItemsAndQuantitySum()}</IonBadge>
												<Icon icon={basket} classname='icon' />
											</div>
											<div>
												<SmallText color="gray">
													{Basket._getTotal()}
												</SmallText>
											</div>
										</div>
									</IonButton> :
									!authPages && !isWebConfig() ?
										config.appType.hasLoyalty ? <IonButton color='dark' button clear onClick={() => forwardTo('/loyalty', { tab: 'scan' })}><IonIcon slot="icon-only" icon={scan} /></IonButton> : null
										: null
							}
						</IonButtons>
					</IonToolbar>
					<IonToolbar color="transparent" />
				</IonHeader>
				: null}
		</>
	)
}

const stateToProps = store => {
	const { orders } = store
	return {
		basketUpdated: orders.basketUpdated
	}
}

export default connect(stateToProps)(withRouter(withTranslation(StaticHeader)))
