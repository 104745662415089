import React from "react";
import { connect } from "react-redux";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonRadioGroup,
  IonRadio,
} from "@ionic/react";
import Layout from "../../components/layout";
import { Title, SmallText, Spacer } from "../../components/common";
import { withTranslation } from "../../lib/translate";
import moment from "../../lib/moment";
import {
  forwardToDeliveryOption,
  isEmptyObject,
  isObject,
  isArray,
  cutoffTime,
  isDefined,
} from "../../lib/utils";
import { setDeliveryTime, setDeliveryOption } from "../../store/actions";
import { setCommonModal } from "../../store/common/actions";
import {
  getIkentooMenu,
  getIkentooMenusForLocation,
} from "../../store/restaurants/actions";
import Mobiscroll from "../../components/mobiscroll";
import Basket from "../../lib/basket";
import Loading from "../../components/spinner";
import NoData from "../../components/noData";
import {
  formatDataForTime,
  getMenusForSelectedTime,
} from "../../screens/clickAndCollect";
import { getConfig } from "../../appConfig";
import "./index.css";

const { SelectOption } = Mobiscroll;
//const customTime = '2020-06-09T05:59:00.000'

const { getRestaurant } = Basket;

export const checkSnoozedTimes = (store, order_type) => {
  if (!store) {
    return null;
  }
  let snoozed_disabled = [];
  if (store.hasOwnProperty("snoozed_times")) {
    const snoozed_times = JSON.parse(store.snoozed_times);
    snoozed_disabled.push(...(snoozed_times[order_type] || (snoozed_times.constructor === Array ? snoozed_times : [])));
  }
  if (store.hasOwnProperty("disabled_times")) {
    const disabled_times = JSON.parse(store.disabled_times);
    snoozed_disabled.push(...(disabled_times[order_type] || (disabled_times.constructor === Array ? disabled_times : [])));
  }
  return snoozed_disabled;
};

class DeliveryTime extends React.Component {
  pickerRef = React.createRef();
  pickerRefCharter = React.createRef();
  state = {
    selectedTime: null,
    pickTime: null,
    formatted: null,
  };
  checkDelivery = () => {
    if (
      !Basket.getDeliveryOption() ||
      (!Basket.getDeliveryAddress() && !Basket.getPickUpPoint())
    ) {
      forwardToDeliveryOption();
    }
  };

  componentDidMount() {
    const { deliveryOption } = this.props;
    let dataForDeliveryOption = null;
    if (!deliveryOption) {
      const order_type = Basket.getOrderType();
      const deliveryConfig = getConfig().delivery;
      Object.keys(deliveryConfig).map((key) => {
        if (deliveryConfig[key].id === order_type.toLowerCase()) {
          dataForDeliveryOption = deliveryConfig[key];
        }
        return true;
      });
      this.props.dispatch(setDeliveryOption(dataForDeliveryOption));
    }
    const charterDelivery = deliveryOption
      ? deliveryOption.id === "charter-delivery"
        ? true
        : false
      : false;
    if (charterDelivery || dataForDeliveryOption) {
      this.pickerRefCharter.current.instance.hide();
    } else {
      this.props.dispatch(
        getIkentooMenusForLocation(
          getRestaurant() ? getRestaurant().business_location_id : null,
          {},
          true
        )
      );
      this.pickerRef.current.instance.hide();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.deliveryOption !== prevProps.deliveryOption) {
      // this.checkDelivery()
    }
    if (
      this.props.ikentooMenusForLocation.length !==
      prevProps.ikentooMenusForLocation.length
    ) {
      if (this.props.ikentooMenusForLocation[0]) {
        this.setState({
          selectedIkentooMenu: this.props.ikentooMenusForLocation[0]
            .ikentooMenuId,
        });
      }
    }
  }
  confirmTime = () => {
    const { selectedTime } = this.state;
    const { deliveryOption } = this.props;
    const isCharterDelivery = deliveryOption
      ? deliveryOption.id === "charter-delivery"
        ? true
        : false
      : false;
    const restaurant = getRestaurant();
    const pickTime = moment().format("HH:mm");
    if (selectedTime && !isCharterDelivery) {
      this.props.dispatch(setDeliveryTime(selectedTime));
      Basket.setCollectionTime(selectedTime.formattedDT);
      let restaurant = getRestaurant();
      let slots = restaurant?.delivery_times_json?.slots;
      let cutoffTimeRes = cutoffTime(selectedTime.formattedDT, slots);
      Basket.setCutoffTime(cutoffTimeRes);
    } else if (selectedTime === "asap") {
      Basket.setCollectionTime(null);
      this.props.dispatch(
        getIkentooMenusForLocation(restaurant.business_location_id, {
          pickTime,
          json_time_selector: restaurant
            ? restaurant.charter_delivery_times_json
            : [],
        })
      );
    } else {
      let pickTime = selectedTime;
      this.props.dispatch(setDeliveryTime(selectedTime));
      Basket.setCollectionTime(this.state.formatted);
      this.props.dispatch(
        getIkentooMenusForLocation(restaurant.business_location_id, {
          pickTime,
          json_time_selector: restaurant
            ? restaurant.charter_delivery_times_json
            : [],
        })
      );
    }
    this.continueOnMenu();
  };

  changeIkentooMenus = (event) =>
    this.setState({ selectedIkentooMenu: event.detail.value, error: "" });

  formatDayName = (name, key) => {
    if (name.includes("Today")) {
      name = "Today";
    } else if (name.includes("Tomorrow")) {
      name = "Tomorrow";
    } else {
      name = key;
    }
    return name;
  };

  formatTimes = (deliveryTimesJson) => {
    const { deliveryOption } = this.props;
    const formatSelectOptionDays = [];
    const daysAhead = deliveryTimesJson ? deliveryTimesJson.days_ahead : null;
    let list = [];
    let picker = [];
    let cnt = 0;
    if (deliveryTimesJson) {
      Array(daysAhead)
        .fill("")
        .forEach((day, i) => {
          let formatDay = moment().add(i, "days");
          let formatDayName = formatDay.format("dddd");
          formatSelectOptionDays.push({
            formatDayName,
            formattedDT: formatDay,
          });
        });
      formatSelectOptionDays.forEach((option, i) => {
        let daySlot = deliveryTimesJson.slots[option.formatDayName];
        daySlot.forEach((slot) => {
          let h = parseInt(slot.start_time.split(":")[0]);
          let m = parseInt(slot.start_time.split(":")[1]);
          let prepTime = null;
          prepTime = moment().add(slot.prep_time, "hours");
          const formattedDT = option.formattedDT.hours(h).minutes(m);
          let label = this.formatDayName(
            option.formattedDT.calendar(),
            option.formatDayName
          );
          option.label = label;
          if (prepTime.isBefore(formattedDT)) {
            cnt++;
            let text = "";
            if (label === "Today" || label === "Tomorrow") {
              text =
                label +
                ", " +
                (deliveryOption && deliveryOption.id === "delivery"
                  ? moment(slot.start_time, "HH:mm").format("h:mm a") +
                  " - " +
                  moment(slot.end_time, "HH:mm").format("h:mm a")
                  : moment(slot.start_time, "HH:mm").format("h:mm a"));
            } else {
              text =
                label +
                " " +
                option.formattedDT.format("Do MMMM") +
                ", " +
                (deliveryOption && deliveryOption.id === "delivery"
                  ? moment(slot.start_time, "HH:mm").format("h:mm a") +
                  " - " +
                  moment(slot.end_time, "HH:mm").format("h:mm a")
                  : moment(slot.start_time, "HH:mm").format("h:mm a"));
            }
            if (cnt <= 4) {
              list.push({
                formattedDT: formattedDT.format(),
                text,
                value: text,
                menuIds: slot.menu_ids || null,
              });
            } else {
              picker.push({
                formattedDT: formattedDT.format(),
                text,
                value: text,
                menuIds: slot.menu_ids || null,
              });
            }
          }
        });
      });
      return { list, picker };
    }
    return { list: [], picker: [] };
  };

  continueOnMenu = () => {
    const { dispatch, deliveryOption } = this.props;
    const { selectedIkentooMenu, selectedTime } = this.state;
    const isCharterDelivery = deliveryOption
      ? deliveryOption.id === "charter-delivery"
        ? true
        : false
      : false;
    if (getRestaurant() && selectedIkentooMenu) {
      const choosenRestaurant = getRestaurant();
      const businessLocationId = choosenRestaurant.business_location_id;
      Basket.setCollectionTime(
        isCharterDelivery ? this.state.formatted : selectedTime.formattedDT
      );
      dispatch(getIkentooMenu(selectedIkentooMenu, businessLocationId));
    } else {
      this.setState({ error: "Please select location menu" });
    }
  };

  getMenusForDeliverySelectedTime = (menusForLocation = [], selectedTime) => {
    if (isObject(menusForLocation) && !isArray(menusForLocation)) {
      menusForLocation = [menusForLocation];
    }
    return menusForLocation.filter((menu) => {
      const ikentooMenuId = menu.ikentooMenuId;
      if (!isEmptyObject(selectedTime)) {
        if (selectedTime !== "asap") {
          const target_menu = selectedTime.menuIds.find(
            (i) => i === ikentooMenuId
          );
          if (target_menu) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    });
  };

  setTime = (e, deliverytimes, isPicker) => {
    const { deliveryOption } = this.props;
    const charterDelivery = deliveryOption
      ? deliveryOption.id === "charter-delivery"
        ? true
        : false
      : false;
    const findPickedDT = isPicker
      ? deliverytimes.find((dt) => e.valueText === dt.text)
      : e;
    if (charterDelivery) {
      this.setState({ selectedTime: findPickedDT });
      if (findPickedDT === "asap") {
        return { ...Basket.getRestaurant(), is_asap: true };
      }
    } else {
      let restaurant = Basket.getRestaurant();
      let slots = restaurant?.delivery_times_json?.slots;
      let cutoffTimeRes = cutoffTime(findPickedDT.formattedDT, slots);
      this.setState({ selectedTime: findPickedDT }, () => {
        if (findPickedDT.menuIds.length > 1) {
          this.props.dispatch(setCommonModal("isChooseMenuModalOpen", true));
        } else if (findPickedDT.menuIds.length === 1) {
          this.setState({ selectedIkentooMenu: findPickedDT.menuIds[0] });
        }
      });
      Basket.setCutoffTime(cutoffTimeRes);
    }
  };

  changeTime = (selectedTime, minDT) => {
    if (selectedTime && minDT) {
      let h = parseInt(selectedTime.split(':')[0]);
      let m = parseInt(selectedTime.split(':')[1]);
      const formattedDT = moment(minDT).hours(h).minutes(m);
      this.setState({ pickTime: selectedTime, selectedTime, formatted: formattedDT }, () => {
        Basket.setCollectionTime(formattedDT);
        Basket.setOrderType('charter-delivery');
      });
    } else {
      this.setState({ pickTime: null });
    }
  };
  setPickTime = (inst, minDT) => {
    if (inst.getVal()) {
      this.changeTime(inst.getVal(), minDT);
    } else {
      this.setState({ pickTime: null });
    }
  };
  isClosedToday(opening_times) {
    const opening_times_array = opening_times.split('\r\n')
    const today = moment(new Date(), "YYYY-MM-DD HH:mm:ss").format('dddd')
    let isClose = opening_times_array.filter(open_time => open_time.includes(today))
    return isClose[0]?.includes('CLOSED')

  }
  render() {
    const {
      __,
      deliveryOption,
      isChooseMenuModalOpen,
      ikentooMenusForLocation,
    } = this.props;
    const deliveryOptionBasket = deliveryOption || Basket.getDeliveryOption();
    const { selectedTime, selectedIkentooMenu, pickTime } = this.state;
    const restaurant =
      selectedTime === "asap"
        ? { ...Basket.getRestaurant(), is_asap: true }
        : { ...Basket.getRestaurant(), is_asap: false };
    const isAsap = restaurant?.is_asap;
    const isClose = this.isClosedToday(restaurant.opening_times);
    const charterDelivery = deliveryOptionBasket
      ? deliveryOptionBasket.id === "charter-delivery"
        ? true
        : false
      : false;
    const deliveryTimesJson = restaurant
      ? restaurant.delivery_times_json
      : null;
    const charterTimesJson = restaurant
      ? restaurant.charter_delivery_times_json
      : null;
    const timesJson = deliveryOptionBasket
      ? deliveryOptionBasket.id === "charter-delivery"
        ? charterTimesJson
        : deliveryTimesJson
      : deliveryTimesJson;
    const hasTimeDelivery =
      !isEmptyObject(deliveryTimesJson) &&
      !isEmptyObject(deliveryTimesJson.slots);
    const hasTimeCharterDelivery = !isEmptyObject(charterTimesJson);
    const hasTime = hasTimeDelivery ? hasTimeDelivery : hasTimeCharterDelivery;
    const animationMenuClass = isChooseMenuModalOpen ? "show-up" : "";
    const menus = charterDelivery
      ? getMenusForSelectedTime(
        ikentooMenusForLocation,
        selectedTime,
        charterTimesJson
      )
      : this.getMenusForDeliverySelectedTime(
        ikentooMenusForLocation,
        selectedTime
      );
    const currentDT = moment();
    let minDT = currentDT;
    if (
      restaurant &&
      isDefined(restaurant.charter_delivery_order_slot_lead_time)
    ) {
      minDT.add(restaurant.charter_delivery_order_slot_lead_time, "minutes");
    }
    const snoozedTimes = checkSnoozedTimes(restaurant, "delivery");

    const can_delivery_order = getRestaurant().can_delivery_order;
    const timePickerOptions = formatDataForTime(
      restaurant,
      minDT,
      restaurant.id,
      true,
      snoozedTimes
    );
    const isDisabledButton = () => {
      let flag = false;
      if (can_delivery_order && pickTime && selectedTime) {
        flag = false;
      } else if (can_delivery_order && isAsap) {
        flag = false;
      } else {
        flag = true;
      }

      return flag;
    };

    return (
      <Layout noPadding>
        <div className="flex-row-wrapper absolute-content">
          <div className="scrollable-y">
            <Title>
              {__(
                deliveryOption &&
                  (deliveryOption.id === "delivery" ||
                    deliveryOption.id === "charter-delivery")
                  ? "Select Delivery Time"
                  : "Select Drop-off Time"
              )}
            </Title>
            {hasTime ? (
              <>
                <SmallText>
                  {__(
                    deliveryOption &&
                      (deliveryOption.id === 'delivery' || deliveryOption.id === 'charter-delivery')
                      ? 'Select the time for your order to be delivered'
                      : 'Select your prefered drop-off time',
                  )}
                </SmallText>
                <Spacer />
                <IonList lines="full">
                  {charterDelivery ? (
                    <div>
                      <SelectOption
                        display="inline"
                        onSet={(e, inst) => this.setPickTime(inst, minDT)}
                        data={timePickerOptions}
                        label="Location"
                        value={pickTime}
                        inputStyle="box"
                        placeholder={__('Select Collection Time')}
                        setText={__('OK')}
                        cancelText={__('Cancel')}
                        disabled={this.state.selectedRestaurant === null ? true : false}
                        onInit={() => {
                          if (timePickerOptions.length > 0) {
                            const firstAvailableTime = timePickerOptions.find(
                              (i) => i.value !== null,
                            );
                            if (
                              !pickTime &&
                              firstAvailableTime &&
                              pickTime !== firstAvailableTime.value
                            ) {
                              this.changeTime(firstAvailableTime.value, minDT);
                            }
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <IonRadioGroup
                      value={selectedTime}
                      onIonChange={(e) => this.setTime(e.detail.value)}
                    >
                      {this.formatTimes(timesJson).list.map((dt, index) => {
                        return (
                          <IonItem key={'delivery-time-' + index}>
                            <div tabIndex="-1"></div>
                            <IonRadio color="primary" slot="start" value={dt} />
                            <IonLabel color="dark">
                              <strong>{dt.text}</strong>
                            </IonLabel>
                          </IonItem>
                        );
                      })}
                    </IonRadioGroup>
                  )}
                </IonList>
              </>
            ) : null}
            {/* {can_delivery_order ? (
              !charterDelivery ? (
                this.formatTimes(timesJson).picker.length > 0 ? (
                  <IonButton
                    fill="clear"
                    className="link"
                    color="secondary"
                    onClick={() => {
                      this.pickerRef.current.instance.show();
                    }}
                  >
                    {__(
                      (hasTime ? "Or choose later" : "Choose") +
                      " delivery time"
                    )}
                  </IonButton>
                ) : null
              ) : (
                <IonButton
                  fill="clear"
                  className="link"
                  color="secondary"
                  onClick={() => {
                    this.pickerRefCharter.current.instance.show();
                  }}
                >
                  {__(
                    (hasTime ? "Or choose later" : "Choose") + " delivery time"
                  )}
                </IonButton>
              )
            ) : null} */}
            {charterDelivery ? (
              <div style={{ display: "none" }}>
                <SelectOption
                  display="center"
                  ref={this.pickerRefCharter}
                  onSet={(e, inst) => {
                    this.setPickTime(e, inst, minDT);
                  }}
                  data={timePickerOptions}
                  label="Location"
                  value={pickTime}
                  inputStyle="box"
                  placeholder={__("Select Collection Time")}
                  setText={__("OK")}
                  cancelText={__("Cancel")}
                  disabled={restaurant === null ? true : false}
                // onInit={ () => {
                // 	if (timePickerOptions.length > 0) {
                // 		const firstAvailableTime = timePickerOptions.find(i => i.value !== null)
                // 		if (!pickTime && firstAvailableTime && pickTime !== firstAvailableTime.value) {
                // 			this.changeTime(firstAvailableTime.value, minDT)
                // 		}
                // 	}
                // }}
                />
              </div>
            ) : (
              <div style={{ display: "none" }}>
                <SelectOption
                  ref={this.pickerRef}
                  display="center"
                  onSet={(e) => {
                    this.setTime(e, this.formatTimes(timesJson).picker, true);
                  }}
                  data={this.formatTimes(timesJson).picker}
                  label="Location"
                  inputStyle="box"
                  placeholder={__("Select Collection Time")}
                  setText={__("OK")}
                  cancelText={__("Cancel")}
                />
              </div>
            )}
          </div>
          {isClose ? (
            <div className="flex-min">
              <IonButton
                expand="block"
                color="secondary"
              >
                {__("We are closed")}
              </IonButton>
            </div>
          ) : (
            <div className="flex-min">
              <IonButton
                disabled={isDisabledButton()}
                expand="block"
                color="secondary"
                onClick={() => this.confirmTime()}
              >
                {__("Continue ")}
              </IonButton>
            </div>)}
        </div>
        <div
          className="click-collect-pickers-backdrop"
          style={{ display: isChooseMenuModalOpen ? "" : "none" }}
          onClick={() =>
            this.props.dispatch(setCommonModal("isChooseMenuModalOpen", false))
          }
        ></div>
        <div className={`click-collect-dialog ${animationMenuClass}`}>
          <Loading transparent> {null} </Loading>
          <div className="click-collect-dialog-layout sc-ion-modal-md">
            <div className="click-collect-dialog-header">
              <h3>{__("Choose menu")}</h3>
            </div>
            <div
              className="click-collect-dialog-closer"
              style={{ position: "absolute", right: 0, top: 0 }}
              onClick={() =>
                this.props.dispatch(
                  setCommonModal("isChooseMenuModalOpen", false)
                )
              }
            >
              <ion-icon
                name="close"
                role="img"
                class="md hydrated"
                aria-label="close"
              ></ion-icon>
            </div>
            <div className="click-collect-dialog-content">
              <IonList lines="full">
                <IonRadioGroup
                  onIonChange={this.changeIkentooMenus}
                  value={selectedIkentooMenu}
                >
                  {menus.length === 0 ? (
                    <NoData />
                  ) : (
                    menus.map((menu) => {
                      const { ikentooMenuId, menuName } = menu;
                      return (
                        <IonItem key={ikentooMenuId}>
                          <div tabIndex="-1"></div>
                          <IonRadio
                            color="secondary"
                            slot="start"
                            value={ikentooMenuId}
                          />
                          <IonLabel className="ion-text-wrap" color="dark">
                            {menuName}
                          </IonLabel>
                        </IonItem>
                      );
                    })
                  )}
                </IonRadioGroup>
              </IonList>
            </div>
            <div className="click-collect-dialog-action">
              <IonButton
                disabled={menus.length > 0 ? false : true}
                expand="block"
                color="primary"
                onClick={() => this.continueOnMenu()}
              >
                {__("Next")}
              </IonButton>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { deliveryOption, deliveryAddress, pickUpPoint } = state.orders;
  const { isChooseMenuModalOpen } = state.common;
  const { ikentooMenusForLocation, restaurants } = state.restaurants;
  return {
    deliveryOption,
    deliveryAddress,
    pickUpPoint,
    isChooseMenuModalOpen,
    ikentooMenusForLocation,
    restaurants,
  };
};

export default connect(stateToProps)(withTranslation(DeliveryTime));
