import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IonGrid, IonCol, IonRow, IonRippleEffect } from '@ionic/react'
import { getSocials } from '../../store/actions'
import Layout from '../../components/layout'
import Loading from '../../components/spinner'
import { Title, SmallText, Spacer } from '../../components/common'
import { isDefined, isEmptyObject } from '../../lib/utils'
import { withTranslation } from '../../lib/translate'
import './index.css'
import NoData from '../../components/noData'

const openExternalLink = url => window.open(url, '_system', 'location=yes')

class Bookings extends Component {
	componentDidMount() {
		this.props.dispatch(getSocials())
	}

	render() {
		const { __, social } = this.props
		return (
			<Loading>
				<Layout color headerTitle={__('Book A Table')}>
					<div className="header-wrapper">
						<Title>{__('Book A Table')}</Title>
					</div>
					<Spacer size={2} />
					<div className="frm-wrapper">
						{isDefined(social) && !isEmptyObject(social) ?
							<IonGrid className="no-padding book">
								<IonRow>
									{Object.keys(social || {}).map((key, index) => {
										const value = social[key]
										// const capitalizeLabel = ucFirst(key)
										const capitalizeLabel = key
										return <IonCol key={'book-item-' + index} size="12">
											<div className="square ion-activatable" onClick={() => openExternalLink(value)}>
												<div className="booking-content ">
													<SmallText color="whtie" className="book-label">{__(capitalizeLabel)}</SmallText>
												</div>
												<IonRippleEffect></IonRippleEffect>
											</div>
										</IonCol>
									})}
								</IonRow>
							</IonGrid>
							:
							<NoData label={__('No booking networks')} />
						}
					</div>
				</Layout>
			</Loading>
		)
	}
}

const stateToProps = state => {
	const { social } = state.common
	return {
		social
	}
}

export default connect(stateToProps)(withTranslation(Bookings))
