import React from 'react'
import { IonItem, IonInput, IonButton, IonIcon } from '@ionic/react'
import { connect } from 'react-redux'
import { lockClosed, mail } from 'ionicons/icons'
import { getConfig } from '../../appConfig'
import Layout from '../../components/layout'
import PasswordInput from '../../components/passwordInput'
import { forwardTo, getDefaultRoute } from '../../lib/utils'
import { loginRequest } from '../../store/actions'
import { withTranslation } from '../../lib/translate'
import Loading from '../../components/spinner'
import { FieldError, Title, Spacer } from '../../components/common'
import { validateForm } from '../../lib/utils'
import './index.css'

class Login extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			username: '',
			password: '',
			formErrors: {}
		}
		this.handleInput = this.handleInput.bind(this)
		this.handleLogin = this.handleLogin.bind(this)
		this.formConfig = {
			username: { type: 'email', required: true },
			password: { type: 'password', required: true }
		}
	}

	handleInput (key, val) {
		this.setState({ [key]: val })
	}

	handleLogin () {
		let formErrors = validateForm(this.formConfig, this.state)
		this.setState({ formErrors })
		if (Object.keys(formErrors).length === 0) {
			const { protectedReferrer } = this.props
			const { username, password } = this.state
			this.props.dispatch(loginRequest({ username, password, referrer: protectedReferrer }))
		}
	}

	checkLoginStatus = () => {
		const { loggedIn } = this.props.auth
		if (loggedIn) {
			const defaultRoute = getDefaultRoute()
			forwardTo(defaultRoute.path)
		}
	}

	componentDidUpdate() {
		this.checkLoginStatus()
	}

	componentDidMount() {
		this.checkLoginStatus()
	}

	render () {
		const { __ } = this.props
		const { username, password } = this.state
		return (
			<Loading transparent>
				<Layout headerTitle={ __('Login') } hideBack={true} color="transparent" contentClassName="login-background">
					<div className="absolute-content flex-row-wrapper">
						<div className="flex-min">
							{ getConfig().theme.showHeaderOnAuthRoutes ?
								<>
									<Title>{ __('Welcome back!') } {/* __('You\'ve been missed') */}</Title>
									<Spacer size={3} />
								</>
								:
								<h3 className="login-header">{ __('Click below to register or login') }</h3>
							}
						</div>
						<div>
							<IonItem>
								{ getConfig().theme.showInputIconsOnLogin ?
									<IonIcon slot="start" className="login-icon" size="small" icon={ mail } />
									: null }
								<IonInput placeholder={ __('Enter your email address') } onIonChange={ e => this.handleInput('username', e.target.value) } onIonBlur={ e => this.handleInput('username', e.target.value) } clearInput type="email" pattern="email" inputmode="email" value={ username }/>
							</IonItem>
							<FieldError className="field-error" value={ __(this.state.formErrors.username) } />
							<IonItem>
								{ getConfig().theme.showInputIconsOnLogin ?
									<IonIcon slot="start" className="login-icon" size="small" icon={ lockClosed }/>
									: null }
								<PasswordInput placeholder={ __('Enter your password') } __={ __ } onIonChange={ e => this.handleInput('password', e.target.value) } value={ password }/>
							</IonItem>
							<FieldError className="field-error" value={ __(this.state.formErrors.password) } />
							<Spacer size={ 2 }/>
							<IonButton expand="block" color="secondary" className="uppercase" onClick={() => this.handleLogin()}>{ __('Sign In') }</IonButton>
							<IonButton expand="block" fill="clear" color="secondary" className="link underlined" onClick={ () => forwardTo('/reset-password') }>{ __('Forgot password') }</IonButton>
						</div>
						<div className="flex-min">
							<IonButton expand="block" fill="outline" color="secondary" className="uppercase" onClick={() => forwardTo('/register')}><strong className="new-user">{ __('New User') }?</strong> { __('Create An Account') }</IonButton>
							{/* { getConfig().theme.showHeaderOnAuthRoutes ? null :
								<IonButton expand="block" fill="outline" color="primary" className="default-button white-button" onClick={ () => forwardTo('/register') }>{ __('Register') }</IonButton>
							} */}
						</div>
					</div>
				</Layout>
			</Loading>
		)
	}
}

const stateToProps = state => {
	const { auth, protectedReferrer } = state.profile
	return {
		auth,
		protectedReferrer
	}
}

export default connect(stateToProps)(withTranslation(Login))
