import React, { Component } from 'react'
import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonButton, IonIcon, IonModal } from '@ionic/react'
import { connect } from 'react-redux'
import { withTranslation } from '../../lib/translate'
import { Spacer, BigLabel, NormalText, Subtitle, Title } from '../../components/common'
import { isDefined, forwardTo } from '../../lib/utils'
import { getConfig } from '../../appConfig'
import Header from '../../components/header'
import QRCode from 'qrcode-react'
import { lockClosed } from 'ionicons/icons'
import './index.css'

export class Points extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isOpen: false,
			selectedIndex: null
		}
	}

	toggleModal = (val, index) => {
		this.setState({ isOpen: val, selectedIndex: index })
	}

	splitedExpiryDate = (expDate) => expDate.split('-').reverse().join('/')

	render() {
		const { __, available_balance, rewards, qr_code } = this.props
		const { isOpen, selectedIndex } = this.state
		let rewardRes = rewards
		let selectedReward = null
		if (isDefined(selectedIndex) && isOpen && rewards) {
			selectedReward = rewards[selectedIndex]
		}
		let voucherType = getConfig().vouchersType
		if (voucherType === 3) {
			rewardRes = rewards.map((data) => {
				return data.reward
			})
			if (selectedReward) {
				selectedReward = selectedReward.reward
			}
		}

		return (
			<div className="loyalty-content">
				<BigLabel className="points">{available_balance}</BigLabel>
				<Subtitle className="points-subtitle">{__('Loyalty points')}</Subtitle>
				{/* <IonNote>{ __('Earn points every time you purchase and scan. Use your points for free rewards!') + ' ' + getConfig().general.clientName + ' ' + __('Rewards 1 cent = 1 point')}</IonNote> */}
				{(rewardRes || []).map((reward, index) => {
					return (
						available_balance >= reward.cost ?
							<IonCard key={index} style={{ cursor: 'pointer' }} onClick={() => this.toggleModal(true, index)}>
								{isDefined(!reward.image) ?
									<div className="loyalty-image" style={{ backgroundImage: 'url(' + reward.image + ')' }}></div> :
									<div className="loyalty-image" style={{ backgroundImage: 'url(' + require('../../assets/images/no-photo.png') + ')' }}></div>
								}
								<IonCardHeader>
									<IonCardSubtitle>{__(reward.small_print)}</IonCardSubtitle>
									<IonCardTitle color="secondary">{reward.cost} {__('Points')}</IonCardTitle>
								</IonCardHeader>
								{/* <IonButton expand="block" color="primary">{ __('Redeem') }</IonButton> */}
							</IonCard>
							:
							<IonCard key={index}>
								<div className="loyalty-image" style={{ backgroundImage: 'url(' + reward.image + ')' }}></div>
								<IonCardHeader>
									<IonCardSubtitle>{__(reward.small_print)}</IonCardSubtitle>
									<IonCardTitle color="secondary">{reward.cost} {__('Points')}</IonCardTitle>
								</IonCardHeader>
								{/* <IonButton expand="block" color="primary">{ __('Redeem') }</IonButton> */}
								<div className="cover"><IonIcon icon={lockClosed} color="white" /></div>
							</IonCard>
					)
				})}
				{isDefined(selectedReward) ?
					<IonModal cssClass='order-intro-modal' isOpen={isOpen} backdrop-dismiss="true" onDidDismiss={() => this.toggleModal(false)}>
						<div className="voucher-info-wrapper">
							<div className='header flex-min'>
								<Header title="Voucher Info" hideBack={true} />
								<ion-icon color="white" name="close" role="img" class="md hydrated" aria-label="close" onClick={() => this.toggleModal(false)}></ion-icon>
							</div>
							<div className="order-intro-modal-content">
								{isDefined(!selectedReward.image) ?
									<div className="voucher-info-image" style={{ backgroundImage: 'url(' + selectedReward.image + ')' }}></div> :
									<div className="voucher-info-image" style={{ backgroundImage: 'url(' + require('../../assets/images/no-photo.png') + ')' }}></div>
								}
								<div className='modal-text'>
									<Title className="ion-text-center uppercase voucher-info-title">{selectedReward.name}</Title>
									<NormalText className="ion-text-center no-margin-bottom" tag="p" color="primary">{__(selectedReward.small_print)}</NormalText>
									<NormalText className="ion-text-center no-margin-top" tag="p" color="primary">{__('Expires')}: {this.splitedExpiryDate(selectedReward.expiry_date)}</NormalText>
									<Subtitle className="ion-text-center no-margin-bottom" color="primary" onClick={() => { forwardTo('/terms') }}>{__('Terms & Conditions')}</Subtitle>
									<NormalText className="ion-text-center" tag="p" color="primary">{__(selectedReward.description)}</NormalText>
								</div>
								<Spacer />
								{isDefined(qr_code) ?
									<div className="qr-holder centered">
										<QRCode value={qr_code} size={200} />
									</div>
									:
									<div className='noQrCode'><h5>{__('NO QR CODE')}</h5></div>
								}
								<Spacer />
							</div>
							<div className="flex-min">
								<IonButton expand="block" color="secondary" className="default-button modal-button" onClick={() => this.toggleModal(false)}>{__('Close')}</IonButton>
							</div>
						</div>
					</IonModal> : null
				}
			</div>
		)
		// return (
		// 	<div className="absolute-content points-tab">
		// 		<div className="scrollable-y">
		// 			<Spacer size={ 5 }/>
		// 			<Title>{ __('Loyalty')}</Title>
		// 			<div className="loyalty-badge">
		// 				<div>
		// 					<IonIcon icon={ starOutline }/>
		// 				</div>
		// 			</div>
		// 			<BigLabel className="points">{ available_balance }</BigLabel>
		// 			<NormalText>{ __('Points Balance')}</NormalText>
		// 			<Spacer size={1}/>
		// 			<SmallText>{ __('Worth') }&nbsp;{ Basket._calculatePointsAppliedPrice(available_balance, false, true) }</SmallText>
		// 		</div>
		// 	</div>
		// )
	}
}

const stateToProps = state => {
	return {
		qr_code: state.profile.profile.qr_code
	}
}

export default connect(stateToProps)(withTranslation(Points))
