import React from 'react'
import { IonButton, IonItem, IonInput /*, isPlatform */, IonModal, IonCard, IonCardHeader, IonCardSubtitle } from '@ionic/react'
import { connect } from 'react-redux'
import Loading from '../../components/spinner'
import Layout from '../../components/layout'
import Clickup from '../../components/clickup'
// import Modal from '../../components/modal'
import { Title, Spacer, NormalText, SmallText, FieldError, Subtitle } from '../../components/common'
import { validateForm, isDefined } from '../../lib/utils'
import { withTranslation } from '../../lib/translate'
import { sendVoucherCode } from '../../store/actions'
import { forwardTo } from '../../lib/utils'
import QRCode from 'qrcode-react'
// import moment from '../../lib/moment'
import './index.css'
import NoDataSecond from '../../components/noDataSecond'
import Header from '../../components/header'
// import voucherDummyImg from '../../assets/images/dashboard-03.jpg'

// temporary dummy array
// const dummyVouchers = [
// 	{
// 		id: 'voucher-1',
// 		title: '£5',
// 		subtitle: 'off any meal',
// 		stamp: 'food-1',
// 		className: 'green',
// 		heading: 'Coffee Stamp Reward',
// 		subheading: 'Loyalty Card Free Coffee Reward',
// 		expiration: '03/09/20',
// 		details: 'You received this voucher as part of the ... blah, blah, truc, truc ...',
// 		qr: '??/assets/images/qr-dummy.png'
// 	},
// 	{
// 		id: 'voucher-2',
// 		title: '25% off',
// 		subtitle: 'all day tuesday',
// 		stamp: 'food-1',
// 		className: 'red',
// 		heading: 'All Day Tuesday Special',
// 		subheading: '25% off Coupon',
// 		expiration: '03/05/20',
// 		details: 'You received this voucher as part of the ... blah, blah, truc, truc ...',
// 		qr: '??/assets/images/qr-dummy.png'
// 	},
// 	{
// 		id: 'voucher-3',
// 		title: 'double',
// 		titleSecondary: 'stamps',
// 		description: 'Monday Lunch between 11.30 - 14.30',
// 		stamp: 'food-1',
// 		className: 'black default-font',
// 		heading: 'Double Stamp Reward',
// 		subheading: 'Loyalty Card Reward',
// 		expiration: '03/05/20',
// 		details: 'You received this voucher as part of the ... blah, blah, truc, truc ...',
// 		qr: '??/assets/images/qr-dummy.png'
// 	}
// ]

// const VoucherTitle = ({ children }) => (
// 	<div className="voucher-title ellipsis">{ children }</div>
// )
// const VoucherSubtitle = ({ children }) => (
// 	<div className="voucher-subtitle ellipsis">{ children }</div>
// )
// const VoucherDescription = ({ children }) => (
// 	<div className="voucher-description">{ children }</div>
// )


const VoucherItem = ({ reward, id, __, action }) => {
	return (
		<IonCard onClick={() => action(id)} className='voucher-content'>
			<div className="loyalty-image" style={{ backgroundImage: 'url(' + reward.image + ')' }}></div>
			<IonCardHeader>
				<IonCardSubtitle>{__(reward.small_print)}</IonCardSubtitle>
				{/* <IonCardTitle color="secondary">{reward.cost} { __('Points') }</IonCardTitle> */}
			</IonCardHeader>
		</IonCard>
	)
}

class Vouchers extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			voucherCode: '',
			selectedVoucher: null,
			formErrors: {
				voucherCode: ''
			}
		}
		this.formConfig = {
			voucherCode: { type: 'text', required: true }
		}
	}

	componentDidMount() {
		if (isDefined(this.props.location.state) && isDefined(this.props.location.state.openVoucherModal)) {
			setTimeout(() => {
				this.viewVoucher(this.props.location.state.openVoucherModal)
			}, 500)
		}
	}

	viewVoucher = (id) => {
		const { vouchers } = this.props
		this.setState({ selectedVoucher: vouchers.find((v) => v.id === id) })
	}

	redeemVoucher = () => {
		const { voucherCode } = this.state
		if (voucherCode !== '') {
			//console.log('Redeem voucher, code: "' + this.state.voucherCode + '"')
		}
	}

	clearFields = () => {
		this.setState({ voucherCode: '' })
	}

	sendCode = () => {
		const { dispatch, __ } = this.props
		let formErrors = validateForm(this.formConfig, this.state, __)
		this.setState({ formErrors })
		if (Object.keys(formErrors).length === 0) {
			const { voucherCode } = this.state
			const data = { code: voucherCode.toUpperCase() }
			this.clearFields()
			dispatch(sendVoucherCode(data))
		}
	}

	handlerOfVoucherCode = (e) => {
		const val = e.target.value.toUpperCase()
		this.setState({ voucherCode: val })
	}

	splitedExpiryDate = (expDate) => expDate.split('-').reverse().join('/')

	render() {
		const { __, vouchers, qr_code } = this.props
		const { voucherCode, selectedVoucher } = this.state
		let voucherRes = (vouchers || []).filter((data) => {
			if (data.type === 1) {
				return data
			}
			return false;
		})
		const clickupContent =
			<Clickup label={__('Redeem Offer Code')} height="320">
				<div className="absolute-wrapper flex-row-wrapper">
					<div className="padded no-padding-bottom centered scrollable-y">
						<p className="no-margin-top">{__('Enter a valid offer code below to receive your reward')}</p>
						<div className="lefted no-padding-bottom voucher-item">
							<IonItem style={{ '--min-height': '32px' }}>
								<div className="inline-input">
									<SmallText>{__('Offer Code')}</SmallText>
									<IonInput value={voucherCode} onIonChange={(e) => this.handlerOfVoucherCode(e)} placeholder={__('Enter your offer code')} />
								</div>
								<FieldError className="field-error" value={__(this.state.formErrors.voucherCode)} />
							</IonItem>
						</div>
					</div>
					<div className="padded no-padding-top flex-min">
						<IonButton disabled={voucherCode === ''} color="secondary" expand="block" onClick={this.sendCode}>{__('Submit')}</IonButton>
					</div>
				</div>
			</Clickup>
		return (
			<Loading transparent>
				<Layout headerTitle={__('Vouchers')} scrollY={false}>
					<div className="absolute-content-vocuher flex-row-wrapper">
						<div className="flex-min ion-text-center">
							<Title>{__('Voucher Wallet')}</Title>
							<Spacer size={1} />
						</div>
						<div className="scrollable-y">
							{!voucherRes || voucherRes.length === 0 ? <NoDataSecond label="Your vouchers will appear here" /> : null}
							{voucherRes && voucherRes.map((voucher, index) => {
								return (
									<VoucherItem key={'vc-' + index} {...voucher} __={__} action={this.viewVoucher} />
								)
							})}
							<Spacer size={2} />
						</div>
						<div className="flex-min">
							{clickupContent}
						</div>
					</div>
				</Layout>
				{isDefined(selectedVoucher) ?
					<IonModal cssClass="voucher-intro-modal" isOpen={selectedVoucher} backdrop-dismiss="true" onDidDismiss={() => this.setState({ selectedVoucher: null })}>
						<div className="voucher-info-wrapper">
							<div className='voucher-header flex-min'>
								<Header title="Voucher Info" hideBack={true} />
								<ion-icon color="white" name="close" role="img" class="md hydrated" aria-label="close" onClick={() => this.setState({ selectedVoucher: null })}></ion-icon>
							</div>
							<div className="voucher-intro-modal-content">
								{isDefined(!selectedVoucher.reward.image) ?
									<div className="voucher-info-image" style={{ backgroundImage: 'url(' + selectedVoucher.reward.image + ')' }}></div> :
									<div className="voucher-info-image" style={{ backgroundImage: 'url(' + require('../../assets/images/no-photo.png') + ')' }}></div>
								}
								<div className='modal-text'>
									<Title className="ion-text-center uppercase voucher-info-title">{selectedVoucher.reward.name}</Title>
									<NormalText className="ion-text-center no-margin-bottom" tag="p" color="primary">{__(selectedVoucher.reward.small_print)}</NormalText>
									<NormalText className="ion-text-center no-margin-top" tag="p" color="primary">{__('Expires')}: {this.splitedExpiryDate(selectedVoucher.reward.expiry_date)}</NormalText>
									<Subtitle className="ion-text-center no-margin-bottom" color="primary" onClick={() => { forwardTo('/terms') }}>{__('Terms & Conditions')}</Subtitle>
									<NormalText className="ion-text-center" tag="p" color="primary">{__(selectedVoucher.reward.description)}</NormalText>
								</div>
								<Spacer />
								{isDefined(qr_code) ?
									<div className="qr-holder centered">
										<QRCode value={qr_code} size={200} />
									</div>
									:
									<div className='noQrCode'><h5>{__('NO QR CODE')}</h5></div>
								}
								<Spacer />
							</div>
							<div className="flex-min">
								<IonButton expand="block" color="secondary" className="default-button modal-button" onClick={() => this.setState({ selectedVoucher: null })}>{__('Close')}</IonButton>
							</div>
						</div>
					</IonModal> : null
				}
			</Loading>
		)
	}
}

const stateToProps = state => {
	return {
		vouchers: state.profile.vouchers || [],
		qr_code: state.profile.profile.qr_code
	}
}

export default connect(stateToProps)(withTranslation(Vouchers))
